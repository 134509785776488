<template>
  <div class="tips-page-content">
    <LoadingBlock v-if="loading"/>
    <div class="payment-header-content">
      <HeaderView
        :store="store.name"
        :logo="store.logo_url"
        :address="store.address"
        :background="store.background_url"
      />
    </div>
    <div v-if="success" class="success-block-wrapper">
      <SuccessBlock :transfer-info="transferInfo" :review-success="reviewSuccess" />
    </div>
    <div v-else class="payment-details-content">
      <EmployeeView
        :name="employee.name"
        :photo="employee['photo_url']"
        :target="employee['target_text']"
        :rating="employee['rating'] ?? '0'"
        :rated="employee['rated'] ?? null"
      />
      <TipsBlock
        mode="tips"
        :tips="tips"
        :store="store"
        :color="color"
        :loading="loading"
        :default-option="store['default_option'] ?? 0"
        :tips-commission="store['tips_commission'] ?? 0"
        :store-payment-options="store['payment_options'] ?? []"
        :store-tips-options="store['tips_options'] ?? []"
        @set-tips="setTips"
        @set-tips-commission="setCommission"
      />
      <CommentBlock
        :store="store"
        :color="color"
        :rating="rating"
        :comment="comment"
        @stickers="updateStickers"
        @comment="updateComment"
        @photo="updatePhoto"
        @audio="updateAudio"
        @rate="updateRating"
        @gif="updateGif"
      />
      <CommissionDetails
        v-if="serviceCommission && tips"
        :tips="tips"
        :service-commission-price="serviceCommission"
        :price="0"
      />
      <PaymentInfo
        :color="color"
        :to-pay="toPay"
      />
      <PaymentTypesBlock
        mode="precheck"
        v-if="toPay"
        :tips="tips"
        :color="color"
        :otp="otpModal"
        :loading="loadingCard"
        :payment-enabled="!!tips"
        :payment-systems="paymentSystems"
        :service-commission-price="serviceCommission"
        @hideNav="hideNav"
        @sendOtp="sendOtp"
        @payCard="payCard"
        @transfer="transfer"
        @paySystem="paySystem"
      />
      <ReviewBlock
        v-if="!toPay"
        :gif="gif"
        :store="store"
        :photo="photo"
        :audio="audio"
        :rating="rating"
        :name="userName"
        :gradient="color"
        :phone="userPhone"
        :comment="comment"
        :stickers="stickers"
        :employee="employee.uuid"
        @success="showSuccess"
      />
    </div>
    <van-popup
      v-model="reviewModal"
      position="center"
      :close-on-click-overlay="false"
      class="p-10"
      style="border-radius: 16px"
      @close="reviewModal = false"
    >
      <template>
        <div class="text-center">
          <h3 v-html="$t('thank-you-tips', { 'tips': formattedBalance(reviewTips) })"></h3>
        </div>
        <div class="d-flex" style="gap: 10px">
          <van-button type="primary" @click="createNewReview">
            <div class="d-flex" style="gap: 10px">
              <van-loading
                v-if="reviewLoading"
                type="spinner"
                size="20"
                :color="'#FFFFFF'"
              ></van-loading>
              <span>{{ $t('leave-more-tips') }}</span>
            </div>
          </van-button>
          <van-button
            type="default"
            @click="reviewModal = false"
          >{{ $t('close') }}</van-button>
        </div>
      </template>
    </van-popup>
    <div v-if="!success" class="px-25 py-0">
      <review-agreement-text
        :get-color="color"
        :border="false"
      />
    </div>
    <navigation-bill v-if="client === 'web' && !hideNavigationMenu" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Service from "@/services/api/service";
import {Button, Loading, Notify, Popup} from "vant";
import {useUserAgent} from "@/helpers/useUserAgent";
import LoadingBlock from "@/components/Loading.vue";
import HeaderView from "@/components/HeaderView.vue";
import TipsBlock from "@/views/precheck/TipsBlock.vue";
import PaymentInfo from "@/components/PaymentInfo.vue";
import ReviewBlock from "@/components/ReviewBlock.vue";
import SuccessBlock from "@/components/SuccessBlock.vue";
import CommentBlock from "@/views/precheck/CommentBlock.vue";
import NavigationBill from "@/components/NavigationBill.vue";
import EmployeeView from "@/views/precheck/EmployeeView.vue";
import CommissionDetails from "@/components/CommissionDetails.vue";
import PaymentTypesBlock from "@/components/PaymentTypesBlock.vue";
import ReviewAgreementText from "@/components/formblock-items/ReviewAgreementText.vue";

export default {
  title: "Оплата чаевых",
  name: "TipsPage",
  components: {
    "van-loading": Loading,
    "van-button": Button,
    ReviewAgreementText,
    "van-popup": Popup,
    CommissionDetails,
    PaymentTypesBlock,
    NavigationBill,
    EmployeeView,
    CommentBlock,
    SuccessBlock,
    LoadingBlock,
    ReviewBlock,
    PaymentInfo,
    HeaderView,
    TipsBlock,
  },
  data() {
    return {
      gif: "",
      tips: 0,
      store: {},
      photo: "",
      audio: "",
      rating: 0,
      userId: "",
      formKey: 0,
      comment: "",
      stickers: [],
      reviewId: "",
      userName: "",
      userPhone: "",
      reviewTips: 0,
      interval: null,
      success: false,
      otpModal: false,
      stickersList: [],
      transferInfo: {},
      isDelivery: false,
      loadingCard: false,
      reviewModal: false,
      paymentSystems: [],
      serviceCommission: 0,
      reviewLoading: false,
      reviewSuccess: false,
      payForDelivery: false,
      hideNavigationMenu: false
    };
  },
  created() {
    this.showLoading(true)
    this.fetchEmployee(this.$route.params.user).then(({ data }) => {
      this.store = data.data.store
      this.paymentSystems = data.data['payment_systems']
    }).finally(() => {
      this.checkNotip()
      this.showLoading(false)
      if (this.$route.params.review) {
        this.showLoading(true)
        this.fetchReview({
          userId: this.$route.params.user,
          reviewId: this.$route.params.review
        }).then((res) => {
          this.reviewTips = res.data.data.tips_amount
          this.updatePhone(res.data.data.phone)
          this.stickersList = res.data.data.stickers
        }).finally(() => {
          this.showLoading(false)
          this.updateComment(this.review.comment)
          this.updateRating(this.review.rating)
          this.updateName(this.review.name)
          this.checkNotip()
          if (this.review.wallet_payment_id) {
            this.reviewModal = true
          }
          this.interval = setInterval(() => {
            Service.getReview(this.$route.params.user, this.$route.params.review).then(({ data: response }) => {
              if (response.data.review.wallet_payment_id) {
                this.reviewTips = response.data.tips_amount
                this.reviewModal = true
                clearInterval(this.interval)
              }
            })
          }, 3000)
          // if (this.review?.['pos_order']?.['is_delivery']) {
          //   this.isDelivery = true;
          //   this.payForDelivery = true
          // }
          // if (this.review?.['pos_order']?.['payment_type'] == 2) {
          //   this.isDelivery = true;
          //   this.payForDelivery = false;
          // }
          // if (this.review.user)
          //   this.fetchUser(this.review.user.uuid)
        });
      }
    })
  },
  mounted() {
    this.$store.commit("SET_NAVIGATION_BILL_HOME_ROUTE", this.$route.fullPath)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_NAVIGATION_BILL_HOME_ROUTE", from.fullPath)
    next()
  },
  computed: {
    review() {
      return this.$store.getters.review;
    },
    employee() {
      return this.$store.getters.employee;
    },
    loading() {
      return this.$store.state.loading;
    },
    color() {
      return this.store['button_color'] ?? '#1fb7a4'
    },
    toPay() {
      return this.tips + this.serviceCommission
    },
    client() {
      let client = "web";
      if (window.Telegram.WebApp.initData !== "") {
        client = "tg";
      }
      return client;
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  },
  methods: {
    ...mapActions(["fetchEmployee", "fetchReview", "fetchUser", "showLoading"]),
    formattedBalance(amount) {
      amount = amount / 100;
      return amount
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    checkNotip() {
      if (this.$route.query.notip && this.$route.query.notip === "1") {
        this.comment = this.review.comment
        this.rate = this.review.rating
        this.setCommission(0)
        this.setTips(0)
      }
    },
    createNewReview() {
      this.reviewLoading = true
      clearInterval(this.interval)
      Service.reviewCopy(this.$route.params.review).then(({ data: response }) => {
        window.location = '/' + this.$route.params.user + '/' + response.data
        // this.$router.push({
        //   name: 'TipsPage',
        //   params: {
        //     user: this.$route.params.user,
        //     review: response.data
        //   },
        //   query: this.$route.query,
        // })
      }).finally(() => {
        this.reviewLoading = false
        this.reviewModal = false
      })
    },
    showSuccess() {
      this.$store.dispatch("clearResponse");
      this.reviewSuccess = true
      this.transferInfo = null
      this.success = true
    },
    setTips(sum) {
      this.tips = parseInt(sum)
    },
    setCommission(commission) {
      this.serviceCommission = parseInt(commission)
    },
    transfer(transferInfo) {
      this.success = true
      this.transferInfo = transferInfo
    },
    updateComment(value) {
      this.comment = value
    },
    updateRating(value) {
      this.rating = value
    },
    updateGif(value) {
      this.gif = value
    },
    updatePhoto(value) {
      this.photo = value
    },
    updateAudio(value) {
      this.audio = value
    },
    updateStickers(value) {
      this.stickers = value
    },
    updatePhone(value) {
      this.userPhone = value
    },
    updateName(value) {
      this.userName = value
    },
    hideNav(value) {
      this.hideNavigationMenu = value
    },
    sendOtp(card, callback) {
      const payload = {
        employee: this.employee.uuid,
        card: card,
        tips_amount: Math.floor(this.toPay * 100),
        rating: this.rating,
        stickers: this.stickers,
        audio: this.audio ? this.audio : null,
        image: this.photo ? this.photo : null,
        gif: this.gif ? this.gif : null,
        comment: this.comment,
      }
      Service.payPrecheck(payload).then((data) => {
        callback(data)
        // this.paydata = data.data.data;
        // this.otp = true;
        // this.loadingCard = false;
        // this.$nextTick(() => {
        //   this.$refs.otpInput.focus();
        // })
      }).catch((err) => {
        callback(null)
        console.log(err);
        // this.loadingCard = false;
      });
    },
    payCard(card, callback) {
      this.loadingCard = true;
      const payload = {
        employee: this.employee.uuid,
        tips_amount: Math.floor(this.toPay * 100),
        tg: false,
        payment_system: 'multicard',
        comment: this.comment,
        stickers: this.stickers,
        audio: this.audio ? this.audio : null,
        image: this.photo ? this.photo : null,
        gif: this.gif ? this.gif : null,
        rating: this.rating,
        card: {
          token: card.token
        }
      }
      this.pay(payload).then((data) => {
        callback(data)
      }).catch((err) => {
        callback(null)
        console.log(err)
      }).finally(() => {
        this.loadingCard = false;
      })
    },
    paySystem(system) {
      this.loadingCard = system
      const payload = {
        employee: this.employee.uuid,
        tips_amount: Math.floor(this.toPay * 100),
        rating: this.rating,
        comment: this.comment,
        tg: false,
        audio: this.audio ? this.audio : null,
        image: this.photo ? this.photo : null,
        gif: this.gif ? this.gif : null,
        stickers: this.stickers,
        payment_system: system,
      }
      this.pay(payload).then((data) => {
        if (system === "multicard") {
          this.multicardPay(data)
          return;
        }
        if (system === "humans" && data?.data?.data?.id && data?.data?.data?.amount) {

          // const paymentId = data?.data?.data?.id
          // const paymentAmount = parseInt(data?.data?.data?.amount) / 100

          // this.humansRedirect(paymentId, paymentAmount)

          return;
        }
        if (data?.data?.data?.redirect) {
          window.location.href = data.data.data.redirect;
        } else {
          if (this.chequeDetails?.paid) {
            Notify({
              type: "warning",
              message: this.$t("cheque-paid-already"),
            });
          } else {
            Notify({
              type: "warning",
              message: this.$t("payment-error"),
            });
          }
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        this.loadingCard = false
      })
    },
    pay(params) {
      return Service.payPrecheck(params)
    },
    multicardPay(data) {
      if (data?.data?.data?.redirect) {
        if (this.useragent === 'multicard') {
          const redirectUrl = URL.parse(data.data.data.redirect)
          const link = URL.parse(redirectUrl.searchParams.get('link'))
          const params = JSON.parse(link.searchParams.get('params'))
          const invoiceUuid = params.details['invoice_uuid']
          const routeParams = {
            fixed_amount: parseInt(data?.data?.data?.amount),
            details: {
              invoice_id: data?.data?.data?.payment_id,
              invoice_uuid: invoiceUuid
            }
          }
          window.flutter_inappwebview.callHandler('deeplink', {
            route: "/payments/checkout/96?params=" + JSON.stringify(routeParams)
          })
        } else {
          window.location.href = data.data.data.redirect
        }
      }
    },
    updateCashbackSum() {
      const cashbackElements = document.getElementsByClassName('cashback-hint')
      const amount = parseInt(this.toPay)
      const percent = 1
      const cashback = parseInt(amount / 100 * percent)
      cashbackElements.forEach(el => {
        el.innerHTML = this.$t('cashback', { percent: cashback + ' ' + this.$t('sum') })
      })
    }
  },
  watch: {
    toPay: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.updateCashbackSum()
          })
        }
      }
    },
    paymentSystems: {
      handler(value) {
        if (value.length) {
          this.$nextTick(() => {
            this.updateCashbackSum()
          })
        }
      }
    }
  }
};
</script>

<style scoped>
.van-button__text {
  white-space: nowrap;
}
.van-button--primary,
.van-button--primary:active,
.van-button--primary:hover {
  background-color: #38aead;
  border-color: #38aead;
}
</style>
